import { Amplify } from "aws-amplify";
import ReactDOM from "react-dom/client";
import AmplifyConfig from "./amplify-config";
import App from "./app/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./shared/AuthService/AuthServiceProvider";
import { LoadingSpinnerProvider } from "./shared/LoadingSpinnerService/LoadingSpinnerServiceProvider";

Amplify.configure(AmplifyConfig.AmplifyConfig.Auth);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    {/* <React.StrictMode> */}
    <LoadingSpinnerProvider>
      <App />
    </LoadingSpinnerProvider>
    {/* </React.StrictMode> */}
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
