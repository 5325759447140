import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import LayoutLeftColumn from "./LayoutLeftColumn";

export default function Layout() {
  const [onRegistration, setOnRegistration] = useState<boolean>(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const ua = navigator.userAgent;
    if (
      ua.includes("iPhone") ||
      ua.includes("iPad") ||
      ua.includes("Android") ||
      ua.includes("Windows Phone") ||
      ua.includes("BlackBerry") ||
      ua.includes("Windows Mobile") ||
      ua.includes("Symbian") ||
      ua.includes("Tizen") ||
      ua.includes("WebOS") ||
      ua.includes("Kindle")
    ) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== "/login" && location.pathname !== "/") {
      setOnRegistration(true);
    } else {
      setOnRegistration(false);
    }
  }, [location]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header fullWidth={onRegistration} isMobile={isMobile} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "#191919" }}>
          <LayoutLeftColumn isMobile={isMobile} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={
            !isMobile
              ? {
                  position: "absolute",
                  left: "50%",
                  top: "80px",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 80px)",
                  minHeight: "calc(75vh - 80px)",
                  width: "50%",
                }
              : { my: 5 }
          }
        >
          {isMobile && onRegistration && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  padding: "5px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "auto",
                  borderRadius: 0,
                  border: "1px solid #f2f2f2",
                }}
                onClick={() =>
                  navigate({ pathname: "/login", search: params.toString() })
                }
              >
                <ArrowBackIcon sx={{ fontSize: "20px", color: "#333" }} />
              </IconButton>
              <Typography color="#838383">
                {" "}
                {t("signup.return-to-login")}
              </Typography>
            </Grid>
          )}
          <Outlet />
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Footer isMobile={isMobile} />
        </Grid>
      )}
    </>
  );
}
