import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface RecoverFormValues {
  email: string;
}

type RecoverPasswordDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formValue: RecoverFormValues) => void;
};

export default function RecoverPasswordDialog({
  open,
  onClose,
  onSubmit,
}: RecoverPasswordDialogProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<RecoverFormValues>({ mode: "all" });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ textAlign: "center", my: 2, mx: 1 }}>
        <IconButton
          data-cy="closeButtonTopRight"
          sx={{ float: "right" }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t("recover.recover-password")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t("recover.text1")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Box sx={{ textAlign: "left" }}>
            <label htmlFor="email-input" style={{ fontWeight: "500" }}>
              {t("login.email")}
            </label>
            <TextField
              id="email-input"
              sx={{ mb: 3, mt: 1, "& .MuiFormHelperText-root": { margin: 0 } }}
              autoComplete="email"
              size="small"
              label={t("login.insert-email")}
              fullWidth
              variant="outlined"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              helperText={
                (errors.email?.type === "required" && (
                  <FormHelperText error>
                    {t("errors.required-field")}
                  </FormHelperText>
                )) ||
                (errors.email?.type === "pattern" && (
                  <FormHelperText error>
                    {t("errors.invalid-email")}
                  </FormHelperText>
                )) ||
                (!errors.email &&
                  !getValues("email") &&
                  t("errors.mail-blank-spaces"))
              }
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {t("recover.text2")}
          </Typography>
          <Button type="submit" variant="contained" disabled={!isValid}>
            {t("recover.verify")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
