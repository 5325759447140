import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { ChangeEvent, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import VerificationCodeDialog, {
  VerificationFormValues,
} from "../components/VerificationCodeDialog";
import useLoadingSpinner from "../shared/LoadingSpinnerService/useLoadingSpinner";
import decryptLang from "../utils/decryptLang";

export interface SignupFormValues {
  name: string;
  surname: string;
  email: string;
  password: string;
  repeatPassword: string;
  lang: string;
  acceptPrivacy: boolean;
  acceptIntegrative: boolean;
  acceptNewsletter: boolean;
  acceptAdvanced: boolean;
}

export default function Signup() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [params] = useSearchParams();
  const loadingSpinner = useLoadingSpinner();
  const [showSignupPassword, setShowSignupPassword] = useState<boolean>(false);
  const [showRepeatSignupPassword, setShowRepeatSignupPassword] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control,
    getValues,
  } = useForm<SignupFormValues>({
    mode: "all",
    defaultValues: {
      acceptPrivacy: false,
      acceptAdvanced: false,
      acceptIntegrative: false,
      acceptNewsletter: false,
    },
  });

  const onSubmit: SubmitHandler<SignupFormValues> = async (
    formValues: SignupFormValues
  ) => {
    loadingSpinner.show();
    Auth.signUp({
      username: formValues.email.toLowerCase().trim(),
      password: formValues.password,
      attributes: {
        name: `${formValues.name} ${formValues.surname}`,
        family_name: formValues.surname,
        given_name: formValues.name,
        email: formValues.email.toLowerCase().trim(),
        "custom:lang": decryptLang(formValues.lang),
        "custom:privacy": formValues.acceptPrivacy === true ? "1" : "0",
        "custom:tos": formValues.acceptAdvanced === true ? "1" : "0",
        "custom:news": formValues.acceptNewsletter === true ? "1" : "0",
        "custom:integrative_privacy":
          formValues.acceptIntegrative === true ? "1" : "0",
      },
    })
      .then(() => {
        loadingSpinner.hide();
        setOpenDialog(true);
        // toast.success(t("messages.registation-completed"));
        // navigate({ pathname: "/login", search: params.toString() });
      })
      .catch((err) => {
        loadingSpinner.hide();
        if (err.code === "UsernameExistsException") {
          toast.error(t("messages.username-already-exists"));
        } else if (err.code === "InvalidPasswordException") {
          toast.error(t("messages.invalid-password"));
        } else {
          toast.error(t("messages.signup-error"));
        }
      });
  };

  const handleVerification = (formValues: VerificationFormValues) => {
    if (formValues.code) {
      loadingSpinner.show();
      Auth.confirmSignUp(getValues("email").trim(), formValues.code.trim())
        .then(() => {
          Auth.signIn(getValues("email").trim(), getValues("password").trim())
            .then((user: CognitoUser) => {
              const tokens = {
                idToken: user
                  .getSignInUserSession()
                  ?.getIdToken()
                  .getJwtToken(),
                accessToken: user
                  .getSignInUserSession()
                  ?.getAccessToken()
                  .getJwtToken(),
                refreshToken: user
                  .getSignInUserSession()
                  ?.getRefreshToken()
                  .getToken(),
                lang: decryptLang(i18n.language),
                user_id: user.getSignInUserSession()?.getAccessToken().payload
                  .username,
              };
              const url = params.get("redirect_uri");
              if (
                url &&
                tokens.idToken &&
                tokens.accessToken &&
                tokens.refreshToken &&
                tokens.user_id
              ) {
                params.append("idToken", tokens.idToken);
                params.append("accessToken", tokens.accessToken);
                params.append("refreshToken", tokens.refreshToken);
                params.append("user_id", tokens.user_id);
                const saveParams = {};
                params.forEach((value, key) => {
                  saveParams[key] = value;
                });
                sessionStorage.setItem(
                  "wilier-sso-params",
                  JSON.stringify(saveParams)
                );
                sessionStorage.setItem(
                  "wilier-sso-params",
                  JSON.stringify(saveParams)
                );
                loadingSpinner.hide();
                window.location.href = `${url}?${params.toString()}`;
              } else if (
                params.get("user_role") &&
                params.get("user_role")?.toLowerCase() === "dealer" &&
                tokens.accessToken
              ) {
                sessionStorage.setItem(
                  `${process.env.REACT_APP_NAME}-token`,
                  tokens.accessToken
                );
                const saveParams = {};
                params.forEach((value, key) => {
                  saveParams[key] = value;
                });
                sessionStorage.setItem(
                  "wilier-sso-params",
                  JSON.stringify(saveParams)
                );

                loadingSpinner.hide();
                navigate({ pathname: "/homepage", search: params.toString() });
              } else {
                loadingSpinner.hide();
                toast.success(t("messages.verification-completed"));
              }
            })
            .catch(() => {
              loadingSpinner.hide();
              toast.error(t("messages.incorrect-login"));
            });
        })
        .catch(() => {
          loadingSpinner.hide();
          toast.error(t("messages.incorrect-code"));
        });
    } else {
      loadingSpinner.hide();
      toast.error(t("messages.incorrect-code"));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          px: "50px",
          pt: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: "20px", fontWeight: "bold" }}
        >
          {t("signup.create-account")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} md={6}>
              <label htmlFor="name-input" style={{ fontWeight: "500" }}>
                {t("signup.name")}
              </label>
              <TextField
                id="name-input"
                sx={{
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                label={t("signup.insert-name")}
                fullWidth
                variant="outlined"
                {...register("name", {
                  required: true,
                })}
                helperText={
                  errors.name?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor="surname-input" style={{ fontWeight: "500" }}>
                {t("signup.surname")}
              </label>
              <TextField
                id="surname-input"
                sx={{
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                label={t("signup.insert-surname")}
                fullWidth
                variant="outlined"
                {...register("surname", {
                  required: true,
                })}
                helperText={
                  errors.surname?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} md={6}>
              <label htmlFor="email-input" style={{ fontWeight: "500" }}>
                {t("signup.email")}
              </label>
              <TextField
                id="email-input"
                sx={{
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                inputProps={{
                  form: {
                    autocomplete: "off",
                  },
                }}
                size="small"
                label={t("signup.insert-email")}
                fullWidth
                variant="outlined"
                {...register("email", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                helperText={
                  (errors.email?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (errors.email?.type === "pattern" && (
                    <FormHelperText error>
                      {t("errors.invalid-email")}
                    </FormHelperText>
                  )) ||
                  (!errors.email &&
                    !getValues("email") &&
                    t("errors.mail-blank-spaces"))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor="lang-select" style={{ fontWeight: "500" }}>
                {t("signup.lang")}
              </label>
              <Controller
                name="lang"
                control={control}
                rules={{ required: true, minLength: 1 }}
                defaultValue=""
                render={({ field: { name, value, onChange, onBlur, ref } }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      mt: 1,
                      "& .MuiFormHelperText-root": { margin: 0 },
                    }}
                  >
                    <InputLabel id="lang-select-label">
                      {t("signup.insert-lang")}
                    </InputLabel>
                    <Select
                      labelId="lang-select-label"
                      variant="outlined"
                      ref={ref}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                      onChange={(event) => onChange(event as ChangeEvent)}
                    >
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="it">Italiano</MenuItem>
                      <MenuItem value="de">Deutsch</MenuItem>
                      <MenuItem value="fr">Français</MenuItem>
                      <MenuItem value="es">Español</MenuItem>
                    </Select>
                    <FormHelperText error>
                      {errors.lang?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12} md={6}>
              <label htmlFor="password-input" style={{ fontWeight: "500" }}>
                {t("signup.password")}
              </label>
              <Tooltip
                title={t("signup.password-pattern")}
                placement="top"
                enterTouchDelay={0}
                leaveTouchDelay={5000}
              >
                <IconButton sx={{ ml: 1, p: 0 }}>
                  <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>

              <TextField
                id="password-input"
                autoComplete="new-password"
                sx={{
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                label={t("signup.insert-password")}
                fullWidth
                variant="outlined"
                type={showSignupPassword ? "text" : "password"}
                {...register("password", {
                  required: true,
                  minLength: 8,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                  validate: (value) =>
                    value === watch("repeatPassword") ||
                    t("errors.password-not-match"),
                })}
                helperText={
                  (errors.password?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  ((errors.password?.type === "pattern" ||
                    errors.password?.type === "minLength") && (
                    <FormHelperText error>
                      {t("errors.password-pattern")}
                    </FormHelperText>
                  )) ||
                  (!errors.password &&
                    !getValues("password") &&
                    t("errors.password-blank-spaces"))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowSignupPassword(!showSignupPassword)
                        }
                      >
                        {showSignupPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label
                htmlFor="repeat-password-input"
                style={{ fontWeight: "500" }}
              >
                {t("signup.repeat-password")}
              </label>
              <TextField
                id="repeat-password-input"
                sx={{
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                label={t("signup.insert-password")}
                fullWidth
                variant="outlined"
                type={showRepeatSignupPassword ? "text" : "password"}
                {...register("repeatPassword", {
                  required: true,
                  validate: (value) =>
                    value === watch("password") ||
                    t("errors.password-not-match"),
                })}
                helperText={
                  (errors.repeatPassword?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (errors.repeatPassword && (
                    <FormHelperText error>
                      {errors.repeatPassword.message}
                    </FormHelperText>
                  )) ||
                  (!errors.repeatPassword &&
                    !getValues("repeatPassword") &&
                    t("errors.password-blank-spaces"))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowRepeatSignupPassword(!showRepeatSignupPassword)
                        }
                      >
                        {showRepeatSignupPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Controller
            control={control}
            name="acceptPrivacy"
            rules={{ required: true }}
            render={({ field }) => (
              <Checkbox
                disableRipple
                id="privacy-checkbox"
                sx={{ paddingLeft: 0 }}
                {...field}
              />
            )}
          />
          <label htmlFor="privacy-checkbox" style={{ fontWeight: "500" }}>
            {t("signup.privacy")}
          </label>

          <Typography
            variant="caption"
            component="p"
            dangerouslySetInnerHTML={{ __html: t("signup.accept-privacy") }}
          />
          {errors.acceptPrivacy?.type === "required" && (
            <FormHelperText error>{t("errors.required-field")}</FormHelperText>
          )}
          <Controller
            control={control}
            name="acceptIntegrative"
            rules={{ required: true }}
            render={({ field }) => (
              <Checkbox
                disableRipple
                id="integrative-checkbox"
                sx={{ paddingLeft: 0 }}
                {...field}
              />
            )}
          />
          <label htmlFor="integrative-checkbox" style={{ fontWeight: "500" }}>
            {t("signup.integrative-information")}
          </label>
          <Typography variant="caption" component="p">
            {t("signup.accept-integrative-information")}
          </Typography>

          {errors.acceptIntegrative?.type === "required" && (
            <FormHelperText error>{t("errors.required-field")}</FormHelperText>
          )}
          <Controller
            control={control}
            name="acceptNewsletter"
            rules={{ required: false }}
            render={({ field }) => (
              <Checkbox
                disableRipple
                id="newsletter-checkbox"
                sx={{ paddingLeft: 0 }}
                {...field}
              />
            )}
          />
          <label htmlFor="newsletter-checkbox" style={{ fontWeight: "500" }}>
            {t("signup.newsletter")}
          </label>
          <Typography variant="caption" component="p">
            {t("signup.accept-newsletter")}
          </Typography>
          <Controller
            control={control}
            name="acceptAdvanced"
            rules={{ required: false }}
            render={({ field }) => (
              <Checkbox
                disableRipple
                id="advanced-checkbox"
                sx={{ paddingLeft: 0 }}
                {...field}
              />
            )}
          />
          <label htmlFor="advanced-checkbox" style={{ fontWeight: "500" }}>
            {t("signup.advanced-privacy")}
          </label>
          <Typography variant="caption" component="p">
            {t("signup.accept-advanced")}
          </Typography>
          <Box
            sx={{
              py: "30px",
            }}
          >
            <Button variant="contained" disabled={!isValid} type="submit">
              {t("signup.register")}
            </Button>
          </Box>
        </form>
      </Box>
      <VerificationCodeDialog
        open={openDialog}
        insertedEmail={getValues("email")}
        onSubmit={(formValues: VerificationFormValues) =>
          handleVerification(formValues)
        }
      />
    </Box>
  );
}
