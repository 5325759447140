import { useLoadingSpinnerContext } from "./LoadingSpinnerServiceProvider";

export default function useLoadingSpinner() {
  const [loading, setLoading] = useLoadingSpinnerContext();
  const initActive = false;
  return {
    isActive: initActive,
    getIsActive() {
      return loading;
    },
    show() {
      setLoading(true);
    },
    hide() {
      setLoading(false);
    },
  };
}
