import Box from "@mui/material/Box";
import Footer from "./Footer";

type LayoutLeftColumnProps = {
  isMobile: boolean;
};

export default function LayoutLeftColumn({ isMobile }: LayoutLeftColumnProps) {
  return (
    <Box sx={{ height: "100%", minHeight: "75vh" }}>
      <Box
        sx={{
          backgroundImage: `url("./images/login.jpg")`,
          backgroundPosition: "bottom",
          position: "relative",
          height: "100%",
          marginTop: isMobile ? "" : "-80px",
          backgroundSize: "cover",
        }}
      />

      {!isMobile && (
        <Box sx={{ display: "flex", height: "25vh" }}>
          <Footer isMobile={isMobile} />
        </Box>
      )}
    </Box>
  );
}
