import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import Homepage from "../pages/Homepage";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import Signup from "../pages/Signup";
import AuthProtectedRoute from "../shared/AuthProtectedRoute/AuthProtectedRoutes";
import useAuth from "../shared/AuthService/AuthService";
import useLoadingSpinner from "../shared/LoadingSpinnerService/useLoadingSpinner";

function AppRoutes() {
  const auth = useAuth();
  const loadingSpinner = useLoadingSpinner();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const ua = navigator.userAgent;
    if (
      ua.includes("iPhone") ||
      ua.includes("iPad") ||
      ua.includes("Android") ||
      ua.includes("Windows Phone") ||
      ua.includes("BlackBerry") ||
      ua.includes("Windows Mobile") ||
      ua.includes("Symbian") ||
      ua.includes("Tizen") ||
      ua.includes("WebOS") ||
      ua.includes("Kindle")
    ) {
      setIsMobile(true);
    }
  }, []);

  return (
    <BrowserRouter>
      {/* MAIN PATHS */}
      <Routes>
        <Route
          element={
            <>
              {loadingSpinner.getIsActive() === true ? (
                <LoadingSpinner />
              ) : null}
              <Layout />
            </>
          }
        >
          <Route path="/" element={<Navigate to="/login" />} />
          {/* LOGIN PATH */}
          <Route path="/login" element={<Login isMobile={isMobile} />} />
          {/* RESET PWD PATH */}
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* SIGN UP PATH */}
          <Route path="/registration" element={<Signup />} />
        </Route>
        <Route
          path="/homepage"
          element={
            <AuthProtectedRoute
              redirectTo="/login"
              returnChildren={auth.getIsLoggedIn()}
            >
              <Homepage isMobile={isMobile} />
            </AuthProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
