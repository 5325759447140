import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface LoadingSpinnerProps {
  children: ReactNode;
}

type LoadingContextType = [boolean, Dispatch<SetStateAction<boolean>>];

const LoadingSpinnerContext = createContext<LoadingContextType | null>(null);

function LoadingSpinnerProvider({
  children,
}: LoadingSpinnerProps): ReactElement {
  const loadingState = useState<boolean>(false);

  return (
    <LoadingSpinnerContext.Provider value={loadingState}>
      {children}
    </LoadingSpinnerContext.Provider>
  );
}

function useLoadingSpinnerContext(): LoadingContextType {
  const context = useContext(LoadingSpinnerContext);
  if (context === null) {
    throw new Error(
      "Please wrap this function into the LoadingSpinnerProvider"
    );
  }
  return context;
}

export { LoadingSpinnerProvider, useLoadingSpinnerContext };
