import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export interface VerificationFormValues {
  code: string;
}

type VerificationCodeDialogProps = {
  open: boolean;
  insertedEmail: string;
  onSubmit: (formValue: VerificationFormValues) => void;
};

export default function VerificationCodeDialog({
  open,
  insertedEmail,
  onSubmit,
}: VerificationCodeDialogProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<VerificationFormValues>({ mode: "all" });

  const resendCode = () => {
    Auth.resendSignUp(insertedEmail.toLowerCase().trim())
      .then(() => {
        toast.success(t("messages.code-resent"));
      })
      .catch(() => {
        toast.error(t("messages.code-resent-error"));
      });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogContent sx={{ textAlign: "center", my: 2, mx: 1 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t("verify.complete-registration")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t("verify.text1")} {insertedEmail}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Box sx={{ textAlign: "left" }}>
            <label htmlFor="code-input" style={{ fontWeight: "500" }}>
              {t("verify.code")}
            </label>
            <TextField
              id="code-input"
              sx={{ mb: 3, mt: 1, "& .MuiFormHelperText-root": { margin: 0 } }}
              size="small"
              label={t("verify.insert-code")}
              fullWidth
              variant="outlined"
              {...register("code", {
                required: true,
              })}
              helperText={
                errors.code?.type === "required" && (
                  <FormHelperText error>
                    {t("errors.required-field")}
                  </FormHelperText>
                )
              }
            />
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Button
                type="button"
                onClick={() => resendCode()}
                variant="outlined"
              >
                {t("verify.resend-code")}
              </Button>
            </Grid>
            <Grid item xs={6} alignItems="left">
              <Button type="submit" variant="contained" disabled={!isValid}>
                {t("verify.verify")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
