import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useLoadingSpinner from "../shared/LoadingSpinnerService/useLoadingSpinner";

export interface ResetFormValues {
  code: string;
  password: string;
  repeatPassword: string;
}

export default function ResetPassword() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const loadingSpinner = useLoadingSpinner();
  const [params] = useSearchParams();
  const [userEmail, setUserEmail] = useState<string>("");

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showRepeatResetPassword, setShowRepeatResetPassword] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    getValues,
  } = useForm<ResetFormValues>({
    mode: "all",
  });

  useEffect(() => {
    const userStorage = localStorage.getItem("userEmail");
    const userParam = params.get("userEmail");
    if (userStorage) {
      setUserEmail(userStorage);
    } else if (userParam) {
      setUserEmail(userParam);
    } else {
      navigate({ pathname: "/login", search: params.toString() });
    }
  }, []);

  const login = () => {
    loadingSpinner.show();
    Auth.signIn(userEmail, getValues("password").trim())
      .then((user: CognitoUser) => {
        const tokens = {
          idToken: user.getSignInUserSession()?.getIdToken().getJwtToken(),
          accessToken: user
            .getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken(),
          refreshToken: user
            .getSignInUserSession()
            ?.getRefreshToken()
            .getToken(),
          lang: i18n.language,
          user_id: user.getSignInUserSession()?.getAccessToken().payload
            .username,
        };
        const url = params.get("redirect_uri");
        if (
          url &&
          tokens.idToken &&
          tokens.accessToken &&
          tokens.refreshToken &&
          tokens.user_id
        ) {
          params.append("idToken", tokens.idToken);
          params.append("accessToken", tokens.accessToken);
          params.append("refreshToken", tokens.refreshToken);
          params.append("user_id", tokens.user_id);
          const saveParams = {};
          params.forEach((value, key) => {
            saveParams[key] = value;
          });
          sessionStorage.setItem(
            "wilier-sso-params",
            JSON.stringify(saveParams)
          );
          loadingSpinner.hide();
          window.location.href = `${url}?${params.toString()}`;
        } else if (
          params.get("user_role") &&
          params.get("user_role")?.toLowerCase() === "dealer" &&
          tokens.accessToken
        ) {
          sessionStorage.setItem(
            `${process.env.REACT_APP_NAME}-token`,
            tokens.accessToken
          );
          const saveParams = {};
          params.forEach((value, key) => {
            saveParams[key] = value;
          });
          sessionStorage.setItem(
            "wilier-sso-params",
            JSON.stringify(saveParams)
          );
          loadingSpinner.hide();
          navigate({ pathname: "/homepage", search: params.toString() });
        } else {
          loadingSpinner.hide();
          toast.success(t("messages.login-completed"));
        }
      })
      .catch(() => {
        loadingSpinner.hide();
        toast.error(t("messages.incorrect-login"));
      });
  };

  const onSubmitReset = () => {
    Auth.forgotPasswordSubmit(
      userEmail.toLowerCase().trim(),
      getValues("code"),
      getValues("password")
    )
      .then(() => {
        login();
      })
      .catch((err) => {
        if (err.code === "InvalidPasswordException") {
          toast.error(t("messages.invalid-password"));
        } else {
          toast.error(t("messages.reset-error"));
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          px: "50px",
          py: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: "20px", fontWeight: "bold" }}
        >
          {t("reset.change-password")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmitReset)} style={{ width: "100%" }}>
          <label htmlFor="code-input" style={{ fontWeight: "500" }}>
            {t("verify.code")}
          </label>
          <TextField
            id="code-input"
            sx={{ mb: 3, mt: 1, "& .MuiFormHelperText-root": { margin: 0 } }}
            size="small"
            label={t("verify.insert-code")}
            fullWidth
            variant="outlined"
            {...register("code", {
              required: true,
            })}
            helperText={
              errors.code?.type === "required" && (
                <FormHelperText error>
                  {t("errors.required-field")}
                </FormHelperText>
              )
            }
          />
          <label htmlFor="password-input" style={{ fontWeight: "500" }}>
            {t("signup.password")}
          </label>
          <Tooltip
            title={t("signup.password-pattern")}
            placement="top"
            enterTouchDelay={0}
            leaveTouchDelay={5000}
          >
            <IconButton sx={{ ml: 1, p: 0 }}>
              <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
            </IconButton>
          </Tooltip>
          <TextField
            id="password-input"
            sx={{
              mb: 3,
              mt: 1,
              "& .MuiFormHelperText-root": { margin: 0 },
            }}
            autoComplete="current-password"
            size="small"
            label={t("signup.insert-password")}
            fullWidth
            variant="outlined"
            type={showResetPassword ? "text" : "password"}
            {...register("password", {
              required: true,
              minLength: 8,
              pattern:
                /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
              validate: (value) =>
                value === watch("repeatPassword") ||
                t("errors.password-not-match"),
            })}
            helperText={
              (errors.password?.type === "required" && (
                <FormHelperText error>
                  {t("errors.required-field")}
                </FormHelperText>
              )) ||
              ((errors.password?.type === "pattern" ||
                errors.password?.type === "minLength") && (
                <FormHelperText error>
                  {t("errors.password-pattern")}
                </FormHelperText>
              )) ||
              (!errors.password &&
                !getValues("password") &&
                t("errors.password-blank-spaces"))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowResetPassword(!showResetPassword)}
                  >
                    {showResetPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <label htmlFor="repeat-password-input" style={{ fontWeight: "500" }}>
            {t("signup.repeat-password")}
          </label>
          <TextField
            id="repeat-password-input"
            sx={{
              mb: 3,
              mt: 1,
              "& .MuiFormHelperText-root": { margin: 0 },
            }}
            autoComplete="current-password"
            size="small"
            label={t("signup.insert-password")}
            fullWidth
            variant="outlined"
            type={showRepeatResetPassword ? "text" : "password"}
            {...register("repeatPassword", {
              required: true,
              validate: (value) =>
                value === watch("password") || t("errors.password-not-match"),
            })}
            helperText={
              (errors.repeatPassword?.type === "required" && (
                <FormHelperText error>
                  {t("errors.required-field")}
                </FormHelperText>
              )) ||
              (errors.repeatPassword && (
                <FormHelperText error>
                  {errors.repeatPassword.message}
                </FormHelperText>
              )) ||
              (!errors.repeatPassword &&
                !getValues("repeatPassword") &&
                t("errors.password-blank-spaces"))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowRepeatResetPassword(!showRepeatResetPassword)
                    }
                  >
                    {showRepeatResetPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              py: "30px",
            }}
          >
            <Button variant="contained" disabled={!isValid} type="submit">
              {t("reset.confirm")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
