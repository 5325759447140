import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../i18n";
import defaultTheme from "../utils/defaulTheme";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer theme="colored" autoClose={3000} />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
