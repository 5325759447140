import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type FooterProps = {
  isMobile: boolean;
};

export default function Footer({ isMobile }: FooterProps) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#191919",
        width: isMobile ? "100vw" : "50vw",
        height: "100%",
      }}
    >
      <Grid
        container
        sx={{
          padding: "20px",
          backgroundColor: "#191919",
          height: "100%",
        }}
      >
        <Grid item xs={12} md={6} textAlign={isMobile ? "center" : "left"}>
          <Typography color="#fff" sx={{ fontSize: "16px" }}>
            Wilier Triestina S.p.A
          </Typography>
          <Typography color="#fff" sx={{ fontSize: "16px" }}>
            Via Fratel Venzo, 11
          </Typography>
          <Typography color="#fff" sx={{ fontSize: "16px" }}>
            36028 Rossano Veneto (VI) - Italy
          </Typography>
          <Typography color="#fff" sx={{ fontSize: "16px" }}>
            F +39 0424 540441
          </Typography>
          <br />
          <Typography
            color="#fff"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              mb: "5px",
              justifyContent: isMobile ? "center" : "left",
            }}
          >
            <MailOutlineIcon sx={{ mr: "10px", fontSize: "20px" }} />
            <Link color="secondary" href="mailto:info@wilier.it">
              info@wilier.it
            </Link>
          </Typography>
          <Typography
            color="#fff"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "left",
            }}
          >
            <PhoneIcon sx={{ mr: "10px", fontSize: "20px" }} />
            <Link color="secondary" href="tel:+390424540442">
              +39 0424 540442
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          textAlign={isMobile ? "center" : "left"}
          sx={isMobile ? { mt: 3 } : {}}
        >
          <Typography color="#acacac" sx={{ fontSize: "16px" }}>
            P.Iva 02037060247
          </Typography>
          <Typography
            color="#acacac"
            sx={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: t("footer.cookie-policy") }}
          />
          <Typography
            color="#acacac"
            sx={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: t("footer.privacy-policy") }}
          />
          <Typography
            color="#acacac"
            sx={{ fontSize: "16px", color: "#fff" }}
            dangerouslySetInnerHTML={{ __html: t("footer.company-info") }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
