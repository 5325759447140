export default function decryptLang(lang: string) {
  switch (lang) {
    case "it":
      return "00";
    case "en":
      return "02";
    case "fr":
      return "03";
    case "de":
      return "01";
    case "es":
      return "04";
    default:
      return "00";
  }
}
