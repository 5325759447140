// amplify-config.js.

// -- AWS AMPLIFY CONFIGURATION PARAMETERS --

// Uncomment this to test env vars
// console.log("env", process.env);

const AmplifyConfig = {
  // Existing Auth
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_REGION,
    // region: "eu-central-1",
    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLID,
    // userPoolId: "eu-central-1_dqkYNMiJ6",
    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID,
    // userPoolWebClientId: "7bnojth1ddmfmvfvs4fokikpbj",
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
};

export default { AmplifyConfig };
