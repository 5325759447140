import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import Auth from "./Auth";

interface AuthProviderProps {
  children: ReactNode;
}

type AuthContextType = [Auth, Dispatch<SetStateAction<Auth>>];

const hasToken: string | null = sessionStorage.getItem(
  `${process.env.REACT_APP_NAME}-token`
);

const initState: Auth = {
  isLoggedIn: !!hasToken,
  setIsLoggedIn(state: boolean): void {
    throw new Error("Function not implemented.");
  },
  getIsLoggedIn(): boolean {
    throw new Error("Function not implemented.");
  },
};
const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const authState = useState<Auth>(initState);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("Please wrap this function into the AuthProvider");
  }
  return context;
}
export { AuthProvider, useAuthContext };
