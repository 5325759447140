import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

type HeaderProps = {
  fullWidth: boolean;
  isMobile: boolean;
};

export default function Header({ fullWidth, isMobile }: HeaderProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const changeLanguageHandler = (event: SelectChangeEvent) => {
    localStorage.setItem("i18n", event.target.value.toString());
    i18n.changeLanguage(event.target.value.toString());
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",

        position: "relative",
        zIndex: "10",
        width: "100%",
        height: "80px",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          py: "10px",
          px: isMobile ? "0" : "20px",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #f2f2f2",
        }}
      >
        <img
          src={isMobile ? "./logo-black.png" : "./logo.png"}
          alt="Wilier"
          width={isMobile ? "200px" : "300px"}
          height={isMobile ? "35px" : "52px"}
        />
        {isMobile && (
          <Select
            value={i18n.language}
            label="Language"
            onChange={changeLanguageHandler}
            autoWidth
            sx={{
              color: isMobile ? "#000" : "#ffffff",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                paddingRight: "14px !important",
              },
              "& .MuiSvgIcon-root": { display: "none" },
            }}
          >
            <MenuItem value="it">IT</MenuItem>
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="de">DE</MenuItem>
            <MenuItem value="es">ES</MenuItem>
          </Select>
        )}
      </Grid>
      {fullWidth && !isMobile && (
        <Grid
          item
          xs={3}
          sx={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              padding: "5px",
              marginLeft: "20px",
              marginRight: "20px",
              width: "auto",
              borderRadius: 0,
              border: "1px solid #f2f2f2",
            }}
            onClick={() =>
              navigate({ pathname: "/login", search: params.toString() })
            }
          >
            <ArrowBackIcon sx={{ fontSize: "20px", color: "#333" }} />
          </IconButton>
          <Typography color="#838383">
            {" "}
            {t("signup.return-to-login")}
          </Typography>
        </Grid>
      )}
      {!isMobile && (
        <Grid
          item
          xs={fullWidth ? 3 : 6}
          sx={{
            padding: "10px",
            paddingRight: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Select
            value={i18n.language}
            label="Language"
            onChange={changeLanguageHandler}
            autoWidth
            sx={{
              color: "#000",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                paddingRight: "14px !important",
              },
              "& .MuiSvgIcon-root": { display: "none" },
            }}
          >
            <MenuItem value="it">IT</MenuItem>
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="de">DE</MenuItem>
            <MenuItem value="es">ES</MenuItem>
          </Select>
        </Grid>
      )}
    </Grid>
  );
}
