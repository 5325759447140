import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import decryptLang from "../utils/decryptLang";

type HomepageProps = {
  isMobile: boolean;
};

export default function Homepage({ isMobile }: HomepageProps) {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value.toString());
  };

  const openB2B = () => {
    const query = decryptLang(i18n.language);
    window.open(`https://alaris.wilier.com?lang=${query}`, "_blank");
  };

  const goToWilier = () => {
    window.open(t("homepage.wilier-site"), "_blank");
  };
  const goToJournal = () => {
    window.open(t("homepage.journal-site"), "_blank");
  };
  const goToInfinititamente = () => {
    window.open(t("homepage.infinitamente-site"), "_blank");
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          position: "relative",
          zIndex: "10",
          width: "100%",
          height: "80px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            padding: "10px",
            justifyContent: "right",
          }}
        >
          <Select
            value={i18n.language}
            label="Language"
            onChange={changeLanguageHandler}
            autoWidth
            sx={{
              color: "#ffffff",
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              "& .MuiSvgIcon-root": { display: "none" },
            }}
          >
            <MenuItem value="it">IT</MenuItem>
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="de">DE</MenuItem>
            <MenuItem value="es">ES</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundImage: `url("./images/homepage/homepage.png")`,
          backgroundPosition: "center",
          height: isMobile ? "30vh" : "75vh",
          position: "relative",
          marginTop: "-80px",
          backgroundSize: "cover",
        }}
      />
      <Box sx={isMobile ? { mt: 8, px: "20px" } : { mt: 8, px: "300px" }}>
        <Typography variant="h4" textAlign="center">
          {t("homepage.title")}
        </Typography>
        <br />
        <Typography variant="body1" textAlign="justify" fontWeight="300">
          {t("homepage.subtitle1")}
        </Typography>
        <br />
        <Typography variant="body1" textAlign="justify" fontWeight="300">
          {t("homepage.subtitle2")}
        </Typography>
        <br />
        {/* <Grid container>
          <Grid xs={12} md={6}>
            <img src="./images/homepage/pannelli.jpeg" alt="pannelli" />
          </Grid>
          <Grid xs={12} md={6}>
            <Typography variant="body1" fontWeight="300">
              {t("homepage.text-row-1")}
            </Typography>
          </Grid>
        </Grid> */}
      </Box>
      <Box
        sx={isMobile ? { mt: 5, px: "20px" } : { mt: 5, px: 10 }}
        textAlign="center"
      >
        <Button onClick={openB2B} variant="contained">
          {t("homepage.start")}
        </Button>
      </Box>
      <Box
        sx={
          isMobile
            ? {
                mt: 5,
                px: "20px",
                py: 5,
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
              }
            : {
                mt: 5,
                px: 10,
                py: 5,
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
              }
        }
      >
        <Typography variant="h4" textAlign="center">
          {t("homepage.social")}
        </Typography>
        {isMobile ? (
          <Grid
            container
            sx={{
              marginTop: isMobile ? 5 : 2,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item textAlign="center" xs={12 / 5}>
              <a
                href="https://twitter.com/WilierTriestina"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50px"
                  src="./images/homepage/twitter.png"
                  alt="wilier-twitter"
                />
              </a>
            </Grid>
            <Grid item textAlign="center" xs={12 / 5}>
              <a
                href="https://www.facebook.com/wiliertriestina"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50px"
                  src="./images/homepage/facebook.png"
                  alt="wilier-facebook"
                />
              </a>
            </Grid>
            <Grid item textAlign="center" xs={12 / 5}>
              <a
                href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHD5NjEjs72_gAAAXlf361Inl8CohWScTbtoZWG338GD8vLqpO8_ktbiZBEAJCCBSWaFEVLQDIjsKSNO3R_nEqb3xsgbWAgfwR7op9kvttlEx2pH7cOstaHI4T0hCppbFO9NqQ=&originalReferer=https://wilier.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fwilier-triestina-spa%2F"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50px"
                  src="./images/homepage/linkedin.png"
                  alt="wilier-linkedin"
                />
              </a>
            </Grid>
            <Grid item textAlign="center" xs={12 / 5}>
              <a
                href="https://www.youtube.com/user/WilierChannel"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50px"
                  src="./images/homepage/youtube.png"
                  alt="wilier-youtube"
                />
              </a>
            </Grid>
            <Grid item textAlign="center" xs={12 / 5}>
              <a
                href="https://www.instagram.com/wiliertriestina/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50px"
                  src="./images/homepage/instagram.png"
                  alt="wilier-instagram"
                />
              </a>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <a
              href="https://twitter.com/WilierTriestina"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <img
                width="50px"
                src="./images/homepage/twitter.png"
                alt="wilier-twitter"
              />
            </a>
            <a
              href="https://www.facebook.com/wiliertriestina"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <img
                width="50px"
                src="./images/homepage/facebook.png"
                alt="wilier-facebook"
              />
            </a>
            <a
              href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHD5NjEjs72_gAAAXlf361Inl8CohWScTbtoZWG338GD8vLqpO8_ktbiZBEAJCCBSWaFEVLQDIjsKSNO3R_nEqb3xsgbWAgfwR7op9kvttlEx2pH7cOstaHI4T0hCppbFO9NqQ=&originalReferer=https://wilier.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fwilier-triestina-spa%2F"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <img
                width="50px"
                src="./images/homepage/linkedin.png"
                alt="wilier-linkedin"
              />
            </a>
            <a
              href="https://www.youtube.com/user/WilierChannel"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <img
                width="50px"
                src="./images/homepage/youtube.png"
                alt="wilier-youtube"
              />
            </a>
            <a
              href="https://www.instagram.com/wiliertriestina/"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <img
                width="50px"
                src="./images/homepage/instagram.png"
                alt="wilier-instagram"
              />
            </a>
          </Box>
        )}
      </Box>
      <Grid
        container
        sx={isMobile ? { mt: 5, px: "20px" } : { mt: 5, px: 10 }}
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: "#d3d3d370",
              py: 8,
              minHeight: "190px",
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={goToWilier}
          >
            <Typography variant="h4">{t("homepage.block1")}</Typography>
            <br />
            <Typography variant="h4">WILIER.COM</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: "#d3d3d370",
              py: 8,
              minHeight: "190px",
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={goToJournal}
          >
            <Typography variant="h4">{t("homepage.block2")}</Typography>
            <br />
            <Box sx={{ width: "80%", margin: "auto" }}>
              <img
                src="./images/homepage/journal-logo.png"
                alt="journal"
                style={{ width: "100%" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: "#d3d3d370",
              py: 8,
              minHeight: "190px",
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={goToInfinititamente}
          >
            <Typography variant="h4">{t("homepage.block3")}</Typography>
            <br />
            <Typography variant="h4">INFINITAMENTE</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ background: "#191919", textAlign: "center", py: 5, mt: 5 }}>
        <Typography color="#fff" sx={{ fontSize: "16px" }}>
          Wilier Triestina S.p.A
        </Typography>
        <Typography color="#fff" sx={{ fontSize: "16px" }}>
          Via Fratel Venzo, 11 - 36028 - Rossano Veneto (VI) - Italy
        </Typography>
        <Typography color="#fff" sx={{ fontSize: "16px" }}>
          T +39 0424 540442 - F +39 0424 540441 - info@wilier.it
        </Typography>
        <Typography
          color="#acacac"
          sx={{ fontSize: "16px" }}
          component="span"
          dangerouslySetInnerHTML={{ __html: t("footer.cookie-policy") }}
        />{" "}
        <Typography color="#acacac" component="span" sx={{ fontSize: "16px" }}>
          {" "}
          |{" "}
        </Typography>
        <Typography
          color="#acacac"
          component="span"
          sx={{ fontSize: "16px" }}
          dangerouslySetInnerHTML={{ __html: t("footer.privacy-policy") }}
        />{" "}
        <Typography color="#acacac" component="span" sx={{ fontSize: "16px" }}>
          {" "}
          |{" "}
        </Typography>
        <Typography
          color="#acacac"
          component="span"
          sx={{ fontSize: "16px", color: "#fff" }}
          dangerouslySetInnerHTML={{ __html: t("footer.company-info") }}
        />
      </Box>
    </Box>
  );
}
