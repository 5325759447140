import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import RecoverPasswordDialog, {
  RecoverFormValues,
} from "../components/RecoverPasswordDialog";
import VerificationCodeDialog, {
  VerificationFormValues,
} from "../components/VerificationCodeDialog";
import useLoadingSpinner from "../shared/LoadingSpinnerService/useLoadingSpinner";

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface NewPasswordFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

type LoginProps = {
  isMobile: boolean;
};

export default function Login({ isMobile }: LoginProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const loadingSpinner = useLoadingSpinner();
  const [params] = useSearchParams();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showLoginPassword, setShowLoginPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] =
    useState<boolean>(false);
  const [openVerifyDialog, setOpenVerifyDialog] = useState<boolean>(false);
  const [newPasswordUser, setNewPasswordUser] = useState<CognitoUser>();
  const [newPasswordRequired, setNewPasswordRequired] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<LoginFormValues>({ mode: "all" });

  const {
    register: registerNewPassword,
    handleSubmit: handleSubmitNewPassword,
    getValues: getValuesNewPassword,
    setValue: setValuesNewPassword,
    watch: watchNewPassword,
    formState: { errors: errorsNewPassword, isValid: isValidNewPassword },
  } = useForm<NewPasswordFormValues>({ mode: "all" });

  useEffect(() => {
    const logoutParam = params.get("logout");
    if (logoutParam && logoutParam === "true") {
      Auth.signOut()
        .then(() => {
          params.delete("logout");
          const url = params.get("redirect_uri");
          const urlAfterLogout = params.get("url_after_logout");
          sessionStorage.removeItem("wilier-sso-params");
          if (url) {
            window.location.href = `${url}?${params.toString()}`;
          } else if (urlAfterLogout) {
            params.delete("url_after_logout");
            params.set("redirect_uri", urlAfterLogout);
            navigate({
              pathname: `/login`,
              search: params.toString(),
            });
          } else {
            navigate({ pathname: "/login", search: params.toString() });
          }
        })
        .catch(() => false);
    } else {
      Auth.currentSession()
        .then((user: CognitoUserSession) => {
          const url = params.get("redirect_uri");
          const tokens = {
            idToken: user.getIdToken().getJwtToken(),
            accessToken: user.getAccessToken().getJwtToken(),
            refreshToken: user.getRefreshToken().getToken(),
            lang: i18n.language,
            user_id: user.getAccessToken().payload.username,
          };
          if (
            url &&
            tokens.idToken &&
            tokens.accessToken &&
            tokens.refreshToken &&
            tokens.user_id
          ) {
            params.append("idToken", tokens.idToken);
            params.append("accessToken", tokens.accessToken);
            params.append("refreshToken", tokens.refreshToken);
            params.append("user_id", tokens.user_id);
            // localStorage.setItem(
            //   "Wilier-sso-params",
            //   JSON.stringify(params.getAll())
            // );
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            sessionStorage.setItem(
              "wilier-sso-params",
              JSON.stringify(saveParams)
            );
            window.location.href = `${url}?${params.toString()}`;
          } else if (
            params.get("user_role") &&
            params.get("user_role")?.toLowerCase() === "dealer" &&
            tokens.accessToken
          ) {
            sessionStorage.setItem(
              `${process.env.REACT_APP_NAME}-token`,
              tokens.accessToken
            );
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            navigate({ pathname: "/homepage", search: params.toString() });
          }
        })
        .catch(() => {
          // const savedQueryParams = sessionStorage.getItem("wilier-sso-params");
          // if (savedQueryParams) {
          //   const jsonQueryParmas = JSON.parse(savedQueryParams);
          //   const keys = Object.keys(jsonQueryParmas);
          //   keys.forEach((k) => {
          //     console.log(k);
          //     console.log(jsonQueryParmas[k]);
          //   });
          // }
        });
    }
  }, []);

  const onLogin: SubmitHandler<LoginFormValues> = async () => {
    loadingSpinner.show();
    Auth.signIn(
      getValues("email").toLowerCase().trim(),
      getValues("password").trim()
    )
      .then((user: CognitoUser) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setValuesNewPassword("newPassword", "");
          setValuesNewPassword("repeatNewPassword", "");
          loadingSpinner.hide();
          setNewPasswordRequired(true);
          setNewPasswordUser(user);
        } else {
          const tokens = {
            idToken: user.getSignInUserSession()?.getIdToken().getJwtToken(),
            accessToken: user
              .getSignInUserSession()
              ?.getAccessToken()
              .getJwtToken(),
            refreshToken: user
              .getSignInUserSession()
              ?.getRefreshToken()
              .getToken(),
            lang: i18n.language,
            user_id: user.getSignInUserSession()?.getAccessToken().payload
              .username,
          };
          const url = params.get("redirect_uri");
          if (
            url &&
            tokens.idToken &&
            tokens.accessToken &&
            tokens.refreshToken &&
            tokens.user_id
          ) {
            params.append("idToken", tokens.idToken);
            params.append("accessToken", tokens.accessToken);
            params.append("refreshToken", tokens.refreshToken);
            params.append("user_id", tokens.user_id);
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            sessionStorage.setItem(
              "wilier-sso-params",
              JSON.stringify(saveParams)
            );
            loadingSpinner.hide();
            window.location.href = `${url}?${params.toString()}`;
          } else if (
            params.get("user_role") &&
            params.get("user_role")?.toLowerCase() === "dealer" &&
            tokens.accessToken
          ) {
            sessionStorage.setItem(
              `${process.env.REACT_APP_NAME}-token`,
              tokens.accessToken
            );
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            loadingSpinner.hide();
            navigate({ pathname: "/homepage", search: params.toString() });
          } else {
            loadingSpinner.hide();
            toast.success(t("messages.login-completed"));
          }
        }
      })
      .catch((err) => {
        loadingSpinner.hide();
        if (err.code === "UserNotFoundException") {
          toast.error(t("messages.user-not-found"));
        } else if (err.code === "UserNotConfirmedException") {
          Auth.resendSignUp(getValues("email").toLowerCase().trim())
            .then(() => {
              setOpenVerifyDialog(true);
            })
            .catch(() => {
              toast.error(t("messages.incorrect-login"));
            });
        } else {
          toast.error(t("messages.incorrect-login"));
        }
      });
  };

  const handleRecover = (formValues: RecoverFormValues) => {
    if (formValues.email) {
      loadingSpinner.show();
      Auth.forgotPassword(formValues.email.toLowerCase().trim())
        .then(() => {
          localStorage.setItem("userEmail", formValues.email);
          navigate({ pathname: "/reset-password", search: params.toString() });
        })
        .catch(() => {
          toast.error(t("messages.recover-mail-error"));
        });
    } else {
      toast.error(t("messages.recover-mail-error"));
    }
    loadingSpinner.hide();
  };

  const handleVerification = (formValues: VerificationFormValues) => {
    if (formValues.code) {
      loadingSpinner.show();
      Auth.confirmSignUp(getValues("email").trim(), formValues.code.trim())
        .then(() => {
          Auth.signIn(getValues("email").trim(), getValues("password").trim())
            .then((user: CognitoUser) => {
              const tokens = {
                idToken: user
                  .getSignInUserSession()
                  ?.getIdToken()
                  .getJwtToken(),
                accessToken: user
                  .getSignInUserSession()
                  ?.getAccessToken()
                  .getJwtToken(),
                refreshToken: user
                  .getSignInUserSession()
                  ?.getRefreshToken()
                  .getToken(),
                lang: i18n.language,
                user_id: user.getSignInUserSession()?.getAccessToken().payload
                  .username,
              };
              const url = params.get("redirect_uri");
              if (
                url &&
                tokens.idToken &&
                tokens.accessToken &&
                tokens.refreshToken &&
                tokens.user_id
              ) {
                params.append("idToken", tokens.idToken);
                params.append("accessToken", tokens.accessToken);
                params.append("refreshToken", tokens.refreshToken);
                params.append("user_id", tokens.user_id);
                const saveParams = {};
                params.forEach((value, key) => {
                  saveParams[key] = value;
                });
                sessionStorage.setItem(
                  "wilier-sso-params",
                  JSON.stringify(saveParams)
                );
                loadingSpinner.hide();
                window.location.href = `${url}?${params.toString()}`;
              } else if (
                params.get("user_role") &&
                params.get("user_role")?.toLowerCase() === "dealer" &&
                tokens.accessToken
              ) {
                sessionStorage.setItem(
                  `${process.env.REACT_APP_NAME}-token`,
                  tokens.accessToken
                );
                const saveParams = {};
                params.forEach((value, key) => {
                  saveParams[key] = value;
                });
                loadingSpinner.hide();
                navigate({ pathname: "/homepage", search: params.toString() });
              } else {
                loadingSpinner.hide();
                toast.success(t("messages.verification-completed"));
              }
            })
            .catch(() => {
              loadingSpinner.hide();
              toast.error(t("messages.incorrect-login"));
            });
        })
        .catch(() => {
          loadingSpinner.hide();
          toast.error(t("messages.incorrect-code"));
        });
    } else {
      loadingSpinner.hide();
      toast.error(t("messages.incorrect-code"));
    }
  };

  const onSubmitNewPassword = () => {
    loadingSpinner.show();
    Auth.completeNewPassword(
      newPasswordUser,
      getValuesNewPassword("newPassword").trim()
    ).then(() => {
      Auth.signIn(
        getValues("email").trim(),
        getValuesNewPassword("newPassword").trim()
      )
        .then((user: CognitoUser) => {
          const tokens = {
            idToken: user.getSignInUserSession()?.getIdToken().getJwtToken(),
            accessToken: user
              .getSignInUserSession()
              ?.getAccessToken()
              .getJwtToken(),
            refreshToken: user
              .getSignInUserSession()
              ?.getRefreshToken()
              .getToken(),
            lang: i18n.language,
            user_id: user.getSignInUserSession()?.getAccessToken().payload
              .username,
          };
          const url = params.get("redirect_uri");
          if (
            url &&
            tokens.idToken &&
            tokens.accessToken &&
            tokens.refreshToken &&
            tokens.user_id
          ) {
            params.append("idToken", tokens.idToken);
            params.append("accessToken", tokens.accessToken);
            params.append("refreshToken", tokens.refreshToken);
            params.append("user_id", tokens.user_id);
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            sessionStorage.setItem(
              "wilier-sso-params",
              JSON.stringify(saveParams)
            );
            loadingSpinner.hide();
            window.location.href = `${url}?${params.toString()}`;
          } else if (
            params.get("user_role") &&
            params.get("user_role")?.toLowerCase() === "dealer" &&
            tokens.accessToken
          ) {
            sessionStorage.setItem(
              `${process.env.REACT_APP_NAME}-token`,
              tokens.accessToken
            );
            const saveParams = {};
            params.forEach((value, key) => {
              saveParams[key] = value;
            });
            loadingSpinner.hide();
            navigate({ pathname: "/homepage", search: params.toString() });
          } else {
            loadingSpinner.hide();
            toast.success(t("messages.new-password-completed"));
          }
        })
        .catch(() => {
          loadingSpinner.hide();
          toast.error(t("messages.incorrect-login"));
        });
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          px: "50px",
          py: "20px",
          borderBottom: "1px solid #f2f2f2",
        }}
      >
        {!newPasswordRequired ? (
          <>
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              {t("login.sign-in")}
            </Typography>
            <form onSubmit={handleSubmit(onLogin)} style={{ width: "100%" }}>
              <label htmlFor="email-input" style={{ fontWeight: "500" }}>
                {t("login.email")}
              </label>
              <TextField
                id="email-input"
                sx={{
                  mb: 3,
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                inputProps={{
                  form: {
                    autocomplete: "off",
                  },
                }}
                size="small"
                label={t("login.insert-email")}
                fullWidth
                variant="outlined"
                {...register("email", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                helperText={
                  (errors.email?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (errors.email?.type === "pattern" && (
                    <FormHelperText error>
                      {t("errors.invalid-email")}
                    </FormHelperText>
                  )) ||
                  (!errors.email &&
                    !getValues("email") &&
                    t("errors.mail-blank-spaces"))
                }
              />
              <label htmlFor="password-input" style={{ fontWeight: "500" }}>
                {t("login.password")}
              </label>
              <TextField
                id="password-input"
                sx={{
                  mb: 3,
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                label={t("login.insert-password")}
                fullWidth
                variant="outlined"
                type={showLoginPassword ? "text" : "password"}
                {...register("password", { required: true })}
                helperText={
                  (errors.password?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (!errors.password &&
                    !getValues("password") &&
                    t("errors.password-blank-spaces"))
                }
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowLoginPassword(!showLoginPassword)}
                      >
                        {showLoginPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth={isMobile}
                    type="submit"
                    variant="contained"
                    disabled={!isValid}
                    sx={isMobile ? { marginBottom: 1 } : {}}
                  >
                    {t("login.login")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} textAlign="right">
                  <Button variant="text" onClick={() => setOpenDialog(true)}>
                    {t("login.password-recovery")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              {t("login.sign-in-new-password")}
            </Typography>
            <form
              autoComplete="off"
              onSubmit={handleSubmitNewPassword(onSubmitNewPassword)}
              style={{ width: "100%" }}
            >
              <label htmlFor="email-input" style={{ fontWeight: "500" }}>
                {t("login.email")}
              </label>
              <TextField
                id="email-input"
                sx={{
                  mb: 3,
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                autoComplete="email"
                size="small"
                label={t("login.insert-email")}
                fullWidth
                variant="outlined"
                {...register("email", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                helperText={
                  (errors.email?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (errors.email?.type === "pattern" && (
                    <FormHelperText error>
                      {t("errors.invalid-email")}
                    </FormHelperText>
                  )) ||
                  (!errors.email && t("errors.mail-blank-spaces"))
                }
                disabled
              />
              <label htmlFor="new-password-input" style={{ fontWeight: "500" }}>
                {t("login.new-password")}
              </label>
              <Tooltip
                title={t("signup.password-pattern")}
                placement="top"
                enterTouchDelay={0}
                leaveTouchDelay={5000}
              >
                <IconButton sx={{ ml: 1, p: 0 }}>
                  <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>
              <TextField
                id="new-password-input"
                sx={{
                  mb: 3,
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                size="small"
                autoComplete="current-password"
                label={t("login.insert-new-password")}
                fullWidth
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                {...registerNewPassword("newPassword", {
                  required: true,
                  minLength: 8,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                  validate: (value) =>
                    value === watchNewPassword("repeatNewPassword") ||
                    t("errors.password-not-match"),
                })}
                helperText={
                  (errorsNewPassword.newPassword?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  ((errorsNewPassword.newPassword?.type === "pattern" ||
                    errorsNewPassword.newPassword?.type === "minLength") && (
                    <FormHelperText error>
                      {t("errors.password-pattern")}
                    </FormHelperText>
                  )) ||
                  (!errorsNewPassword.newPassword &&
                    !getValuesNewPassword("newPassword") &&
                    t("errors.password-blank-spaces"))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <label
                htmlFor="repeat-new-password-input"
                style={{ fontWeight: "500" }}
              >
                {t("login.repeat-new-password")}
              </label>
              <TextField
                id="repeat-new-password-input"
                sx={{
                  mb: 3,
                  mt: 1,
                  "& .MuiFormHelperText-root": { margin: 0 },
                }}
                autoComplete="current-password"
                size="small"
                label={t("login.insert-new-password")}
                fullWidth
                variant="outlined"
                type={showRepeatNewPassword ? "text" : "password"}
                {...registerNewPassword("repeatNewPassword", {
                  required: true,
                  validate: (value) =>
                    value === watchNewPassword("newPassword") ||
                    t("errors.password-not-match"),
                })}
                helperText={
                  (errorsNewPassword.repeatNewPassword?.type === "required" && (
                    <FormHelperText error>
                      {t("errors.required-field")}
                    </FormHelperText>
                  )) ||
                  (errorsNewPassword.repeatNewPassword && (
                    <FormHelperText error>
                      {errorsNewPassword.repeatNewPassword.message}
                    </FormHelperText>
                  )) ||
                  (!errorsNewPassword.repeatNewPassword &&
                    !getValuesNewPassword("repeatNewPassword") &&
                    t("errors.password-blank-spaces"))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowRepeatNewPassword(!showRepeatNewPassword)
                        }
                      >
                        {showRepeatNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                disabled={!isValidNewPassword}
              >
                {t("login.confirm")}
              </Button>
            </form>
          </>
        )}
      </Box>
      {params.get("user_role")?.toLowerCase() !== "dealer" &&
        (!isMobile ? (
          <Grid
            container
            sx={{
              width: "100%",
            }}
            alignItems="center"
          >
            <Grid
              item
              textAlign="left"
              xs={12}
              md={6}
              sx={{
                py: "30px",
                px: "50px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("login.not-registered")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                py: "30px",
                px: "50px",
                textAlign: "right",
              }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  navigate({
                    pathname: "/registration",
                    search: params.toString(),
                  })
                }
              >
                {t("login.create-account")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              py: "30px",
              px: "50px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "0.8rem" }}
            >
              {t("login.not-registered")}
              <Button
                variant="text"
                onClick={() =>
                  navigate({
                    pathname: "/registration",
                    search: params.toString(),
                  })
                }
              >
                {t("login.create-account")}
              </Button>
            </Typography>
          </Box>
        ))}
      <RecoverPasswordDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={(formValues: RecoverFormValues) => handleRecover(formValues)}
      />
      <VerificationCodeDialog
        open={openVerifyDialog}
        insertedEmail={getValues("email")}
        onSubmit={(formValues: VerificationFormValues) =>
          handleVerification(formValues)
        }
      />
    </Box>
  );
}
