import { useAuthContext } from "./AuthServiceProvider";

const AuthService = () => {
  const [auth, setAuth] = useAuthContext();

  const hasToken: string | null = sessionStorage.getItem(
    `${process.env.REACT_APP_NAME}-token`
  );

  return {
    isLoggedIn: !!hasToken,
    setIsLoggedIn(state: boolean) {
      this.isLoggedIn = state;
      setAuth(this);
    },
    getIsLoggedIn() {
      return auth.isLoggedIn;
    },
  };
};

export default AuthService;
