import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    active: PaletteColor;
    inactive: PaletteColor;
    textColor: PaletteColor;
  }
  interface PaletteOptions {
    active: PaletteColorOptions;
    inactive: PaletteColor;
    textColor: PaletteColor;
  }
}
const themeOptions: ThemeOptions = {
  palette: {
    active: {
      main: "#fdc30077",
      light: "#fdc300aa",
      dark: "#fdc300aa",
      contrastText: "#fdc300aa",
    },
    inactive: {
      main: "#bdbdbd",
      light: "#bdbdbd",
      dark: "#bdbdbd",
      contrastText: "#bdbdbd",
    },
    textColor: {
      main: "#000000",
      light: "#ffffff",
      dark: "#000000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "NewHero",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      " sans-serif",
    ].join(","),
    h1: {
      fontFamily: "NewHero",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "NewHero",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "NewHero",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "NewHero",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "NewHero",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "NewHero",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    button: {
      fontFamily: "NewHero",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      borderRadius: 0,
      textTransform: "none",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          "& .MuiInputBase-root": {
            marginBottom: "5px",
            borderRadius: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          background: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 0,
          ...(ownerState.variant === "contained" && {
            padding: "12px 30px",
            border: "1px solid #000",
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#000",
            },
          }),
          ...(ownerState.variant === "outlined" && {
            padding: "12px 30px",
            border: "1px solid #000",
            backgroundColor: "#ffffff",
            color: "#000",
            "&:hover": {
              backgroundColor: "#000",
              color: "#fff",
            },
          }),
          ...(ownerState.variant === "text" && {
            color: "#000",
            textDecoration: "underline",
            fontWeight: 400,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: [
            "NewHero",
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            " sans-serif",
          ].join(","),
          textDecoration: "underline",
          textDecorationColor: "#000",
          ...(ownerState.color === "primary" && {
            color: "#000",
          }),
          ...(ownerState.color === "secondary" && {
            color: "#fff",
          }),
        }),
      },
    },
  },
};

const defaultTheme = createTheme(themeOptions);
export default defaultTheme;
